import React from 'react';
import Layout from '../../components/shared/layout';
import TnCMonthlyComponent from '../../components/terms-and-conditions/tnc-monthly.component';

const TnCMonthly = () => {
  return (
    <div className="tnc-page">
      <Layout header={true} isBackBtn={true}>
        <TnCMonthlyComponent></TnCMonthlyComponent>
      </Layout>
    </div>
  )
}

export default TnCMonthly;