import React from 'react';

const TnCMonthlyComponent = () => {
  return (
    <div className="tnc-section bg-white">
      {/* Monthly Repair Contract */}
      <div className="container">
        <div className="text-center title">
          <p>Physical Breakage &amp; Liquid Breakage</p>
          <strong>(Monthly Repair Contract)</strong>
        </div>
        <ol className="tnc-wrapper">
          <p>Terms &amp; Conditions</p>
          <li><h4>Definitions</h4>
            <ol>
              <li><strong>Mobile Device:</strong> Smartphones which should be for personal use only and not to be used for commercial, rental, or profit generation purposes.</li>
              <li><strong>Normal Use:</strong> Use of the Mobile Device in accordance with the Samsung guidelines for Mobile Device usage including but not limited to regular maintenance & up-keep of the Mobile Device, usage of specified protection devices such as voltage stabilizers.</li>
              <li><strong>Breakage:</strong> Any visible damage (save for Water/Liquid Breakage, which need not be visible) occurring to the Mobile Device during the Contract Period, by involuntary and external means, and including Water/Liquid Breakage. </li>
              <li><strong>Water/Liquid Breakage:</strong> Any damage occurring to the Mobile Device during Contract Period, by an involuntary seepage of water or other fluids inside the Mobile Device including but not limited to rainwater.</li>
              <li><strong>BER:</strong> Beyond Economic Repairs’ means cost of a repair is greater than 85% of the Device Purchase Price.</li>
              <li><strong>Distributor’s Warranty:</strong> The original warranty given by Samsung in respect of a Mobile Device.</li>
              <li><strong>Service Fee:</strong> Fee payable by you at the time of registering a repair request with us.</li>
              <li><strong>You/Your:</strong> The Customer mentioned in this Repair Contract.</li>
              <li><strong>Immediate Family Member:</strong> Means Customer’s spouse and/or child/ren.</li>
              <li><strong>Maximum Repair Value</strong> - Recommended Retail Price of the Mobile Device. RRP is based on the price at launch date and may not reflect what the Customer actually pays (e.g. discounted sale price).</li>
            </ol>
          </li>
          <li><h4>Scope of Breakage repair</h4>
            <p>Repairs which are required during the Contract Period as specified in this Repair Contract as requested by you for Breakage including Water/Liquid Breakage, from time to time, in respect of the Mobile Device, subject to the following conditions.</p>
            <ol>
              <li>Repairs are up to Maximum Repair Value. The details relating to a repair and value of any repair(s) or replacement(s) (if Breakage is BER) required is to be assessed and determined by Samsung and shall be final and conclusive.</li>
              <li>Repair performed exclusively by a Samsung accredited service center.</li>
              <li>Samsung will charge you with additional repair fee at applicable market rates, as determined by Samsung, for any subsequent repair after you exhaust the Maximum Repair Value under this Repair Contract. The details relating to a Breakage and the additional repair fee are to be assessed and determined by Samsung which determination shall be final and conclusive.</li>
              <li>The Mobile Device must (a) have been purchased new in the Philippines; (b) have been manufactured/distributed in the Philippines or legally imported into the Philippines; (c) have been sold through the Samsung’s authorized dealer / distributor supported by an invoice &amp; Distributor’s Warranty; (d) at all times have been used in accordance with the Samsung’s guidelines for Mobile Device usage including but not limited to regular maintenance &amp; up keep of the Mobile Device; (e) not have been modified at any time; (f) have been used for personal use only and not for commercial, rental, or profit generation purposes; and (g) have been mainly used by you and your Immediate Family Member, and had been in your possession (or Immediate Family Member as relevant) only.</li>
              <li>Any previous repair to the Mobile Device must (a) have been performed in the Philippines; and (b) have been performed by Samsung accredited service center.</li>
              <li>The Distributor’s Warranty must remain valid throughout its validity period and not been voided or affected by any action by You.</li>
              <li>
                <p>In performing repair, Samsung shall have the option, in its own discretion, without prejudice to the terms and conditions of this Repair Contract:</p>
                <ol>
                  <li>to replace the Mobile Device with a like-for-like model (whether new or used), in the case of BER. The replaced Mobile Device (the spoilt unit) shall become Samsung property. The replacement Mobile Device may be of lower retail value than the original Mobile Device. You will be responsible to backup and restore any data you may have from the replaced Mobile Device to the replacement Mobile Device, where relevant.</li>
                  <li>to outsource or sub-contract repair or any part of it.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li><h4>Other Key Conditions</h4>
            <ol>
              <li>This Repair Contract becomes valid only upon purchase of the Mobile Device.</li>
              <li>This Repair Contract is only valid for the Mobile Device detailed at the beginning of this Repair Contract. This Repair Contract is not assignable or transferable by you or to another device, for any reason (including but not limited to the Mobile Device being sold to a third party), other than in the case set out in clause 2.3.3 below.</li>
              <li>
                <p>This Repair Contract is transferable to another device only in the event of the Mobile Device received from Samsung being dead on arrival (DOA) or replaced by Samsung due to Distributor’s Warranty fault that cannot be repaired under the relevant Distributor’s Warranty, this Repair Contract shall continue to cover the replacement device provided that you notify us by calling or in writing, through the method mentioned in “Repair Request Process” section below (a) of the DOA within 30 days from the date of the invoice; (b) of the details of the replacement device within 30 days from the date of your receipt of the replacement device. The Device model and IMEI number will be updated accordingly.</p>
                <p>For these purposes, “DOA” refers to a Mobile Device (i) being purchased  and not taken immediately from a physical outlet and which is later delivered to you; (ii) upon delivery is found to be completely inoperative; and (iii) is immediately returned to Samsung or the outlet where you purchased the Mobile Device for replacement.</p>
              </li>
              <li>You may cancel this Repair Contract at any time by calling us at 02-464-3434 between 9am to 8pm Monday to Sunday (including public holidays), giving a minimum notice of seven (7) calendar days prior to the date when the cancellation is intended to take effect. Following your notification your Mobile Device will only be covered until the last day of coverage you have paid for.  You have no right to file a repair request after the cancellation of your Repair Contract and you will not be entitled to any refund.</li>
              <li>Samsung may cancel this Repair Contract by serving a seven (7) calendar day notice in writing to your email address as stated above. In the event of cancellation and termination caused by your failure to make payment of the Monthly Repair Contract Fee or any other reasons for termination as set out below, you will not be entitled to file a repair request and you will not be entitled for any refund.</li>
              <li>Upon cancellation, your Mobile Device will only be covered until the last day of coverage. You will receive a SMS with the final day of coverage upon cancellation.</li>
            </ol>
          </li>
          <li><h4>What's not Included</h4>
            <p>This Repair Contract is only for Breakage including Water/Liquid Breakage, and for the avoidance of doubt, does not include repair or services of or for the following.</p>
            <ol>
              <li>Breakage caused by violating Manufacturer’s Warranty, abuse or negligence.</li>
              <li>Regular wear and tear or gradual deterioration.</li>
              <li>Replacement of or support for data, software, music etc. i.e. any data stored on device.</li>
              <li>Breakage caused by or attributed to the operation of a software virus or any other software-based malfunction; or natural causes, fire, water damage, terrorism, or power surge.</li>
              <li>Repair implicitly or explicitly covered by Samsung’s warranty or repair guarantee under this Repair Contract.</li>
              <li>Replacement   of   any   consumable   items   or   accessories   such   as   batteries,   chargers, headphones etc.</li>
              <li>Cosmetic  damage  to  the  Mobile  Device,  such  as  damage  to  paintwork,  Mobile  Device finish, dents or scratches.</li>
              <li>Any defects that are subject of the Samsung’s recall.</li>
              <li>Any circumstances where the conditions in clause 1.2 above are not met.</li>
              <li>Cost that you incurred by yourself to repair the Mobile Device with a third party.</li>
              <li>Breakage arising from war, war like operations (whether war declared or not), act of foreign enemy,  hostilities,  civil  war,  rebellion,  insurrection,  civil  commotion,  military  usurped power, seizure, capture, confiscation, arrest, restraints and/or detainment by order of any government or any other authority.</li>
              <li>Any  form  of  physical  loss  or  untraceability  of  the  Mobile  Device,  or  any  form  of consequential or incidental loss suffered by you in respect of the Mobile Device. Breakage other than cracked screen.</li>
              <li>Breakage  arising  from  nuclear  reaction,  nuclear  radiation  or  radioactive  contamination from any source whatsoever.</li>
              <li>Breakage arising from overload, experiments or tests requiring the imposition of abnormal conditions.</li>
              <li>Gradually  developing  flaws,  defects,  cracks  or  partial  fractures  in  any  part,  although  at some future time repair or renewal of the parts affected may be necessary.</li>
              <li>Deterioration of or wearing away or wearing out of any part, marring or scratching of the Mobile Device caused by external force or naturally resulting from normal use or exposure.</li>
              <li>Breakage arising out of any intentional act towards the Mobile Device including but not limited to incorrect installation or incorrect set-up. Damage to any electronic data and/or Mobile   Device   software/operating   system,   storage   media,   data/records   or   similar intangible items and damage to the SIM card / memory card of the Mobile Device.</li>
              <li>Any type of electrical or mechanical breakdown including electrical failure or short-circuit.</li>
              <li>Breakage arising from internal leakage of the battery.</li>
              <li>Any  failure  of  the  Mobile  Device  to  operate  as  a  result  of  problems  with  respect  to  the network infrastructure, Beneficiary’s network subscription or similar service issues</li>
            </ol>
          </li>
          <li><h4>Payment Method</h4>
            <ol>
              <li>Payment of monthly Repair Contract Fee is done using Customer’s credit card.</li>
              <li>Payment will be made through an auto debit mechanism carried out every month until written notice is received stating otherwise.</li>
              <li>We will attempt to charge 3 (three) times on a Customer registered credit card and in the event of failed payment on the 23rd, 26th and 30th day from the first recurring payment was made, then the Repair Contract will end automatically.</li>
              <li>Upon failed charging on the 30th day, your coverage will be terminated.</li>
            </ol>
          </li>
          <li><h4>Termination of Repair Contract</h4>
            <p>This Repair Contract will be immediately and automatically terminated in the following events. In such case, no refund will be due to you.</p>
            <ol>
              <li>Fraud, attempted fraud, or non-disclosure of any changes to the Mobile Device that affect this Repair Contract.</li>
              <li>Where the asset serial number is removed, obliterated or altered from the Mobile Device.</li>
              <li>If  repairs  have  been  conducted  by  unauthorized  repair  centers  or  person  at  any  point during or before the Contract Period.</li>
            </ol>
          </li>
          <li>
            <h4>Repair Request Process</h4>
            <ol>
              <li>Repair request must be submitted within 14 days from the date when the Breakage occurred. Otherwise, Samsung reserves the right to deny the repair request.</li>
              <li><p>Notify Samsung of Breakage by:</p>
                <ol>
                  <li>Visiting <a href="https://ph.careplus.co" target="_blank" rel="noopener noreferrer">https://ph.careplus.co</a>; or</li>
                  <li>Calling 02-464-3434; Monday to Sunday 9:00 am to 8:00 pm including public holidays.</li>
                </ol>
              </li>
              <li>Samsung will register the repair request and inform you regarding the Breakage Service Fee or BER Service Fee that you must pay (as applicable).</li>
              <li>Samsung schedules pickup of the Mobile Device once you have paid the Breakage Service Fee or BER Service Fee (as applicable).</li>
              <li>Samsung gets the Mobile Device repaired through its service network of Samsung accredited service centers.</li>
              <li>Samsung dispatches and delivers the repaired Mobile Device to you.</li>
            </ol>
          </li>
          <li>
            <h4>Marketing Consent</h4>
            <p>You hereby agree that your personal information which Samsung obtains because of this Repair Contract may be disclosed by Samsung to any individual, company, whether affiliated to Samsung or otherwise, and within and outside the Philippines, for marketing and other relevant purposes.</p>
          </li>
          <li>
            <h4>Governing Law &amp; Jurisdiction</h4>
            <p>The  construction,  interpretation  and  meaning  of  the  provisions  of  this  Repair  Contract  shall  be determined  in  accordance  with  Philippine  laws  and  shall  be  resolved  exclusively  by  the  courts  of Makati.</p>
          </li>
          <li>
            <h4>Grievance</h4>
            <p>In case of any grievance, you may contact us at 02-464-3434; Monday to Sunday 9:00 am to 8:00 pm including public holidays.</p>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default TnCMonthlyComponent;